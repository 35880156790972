import React from "react";

function PrivacyPolicyDe({site_name, site_host, admin_email}) {
    return (


        <div>
            <h1>Datenschutz-Bestimmungen</h1>

            <p>Zuletzt aktualisiert: 13. März 2022</p>

            <p>Diese Datenschutzrichtlinie
                beschreibt unsere Richtlinien und Verfahren zur Erfassung, Verwendung und Offenlegung Ihrer Daten bei
                der
                Nutzung des Dienstes und informiert Sie über Ihre Datenschutzrechte und darüber, wie das Gesetz Sie
                schützt.</p>

            <p>Wir verwenden Ihre personenbezogenen Daten, um den Service bereitzustellen und zu verbessern.
                Durch die Nutzung des Dienstes stimmen Sie der Erfassung und Nutzung von Informationen gemäß dieser
                Datenschutzrichtlinie zu.</p>

            <h1>Interpretation und Definitionen</h1>

            <h2>Deutung</h2><p>Die Wörter, deren
            Anfangsbuchstabe großgeschrieben wird, haben Bedeutungen, die unter den folgenden Bedingungen definiert
            sind. Die folgenden Definitionen haben die gleiche Bedeutung, unabhängig davon, ob sie im Singular oder im
            Plural erscheinen.</p>

            <h2>Definitionen</h2><p>Für die Zwecke dieser Datenschutzrichtlinie:</p>


            <ul>
                <li><p><strong>Konto</strong> bezeichnet ein einzigartiges Konto, das für Sie erstellt wurde, um auf
                    unseren Dienst oder Teile unseres Dienstes zuzugreifen.</p>

                </li>
                <li><p><strong>Unternehmen</strong> (in dieser Vereinbarung entweder als „das Unternehmen“, „wir“, „uns“
                    oder „unser“ bezeichnet) bezieht sich auf {site_name} .</p>

                </li>
                <li><p><strong>Cookies</strong> sind kleine Dateien, die von einer Website auf Ihrem Computer,
                    Mobilgerät oder einem anderen Gerät abgelegt werden und unter anderem die Details Ihres
                    Browserverlaufs auf dieser Website enthalten.</p>

                </li>
                <li><p><strong>Land</strong> bezieht sich auf: Spanien</p>

                </li>
                <li><p><strong>Gerät</strong> bezeichnet jedes Gerät, das auf den Dienst zugreifen kann, wie z. B. ein
                    Computer, ein Mobiltelefon oder ein digitales Tablet.</p>

                </li>
                <li><p><strong>Personenbezogene Daten</strong> sind alle Informationen, die sich auf eine identifizierte
                    oder identifizierbare Person beziehen.</p>

                </li>
                <li><p><strong>Dienst</strong> bezieht sich auf die Website.</p>

                </li>
                <li><p><strong>Dienstanbieter bezeichnet</strong> jede natürliche oder juristische Person, die die Daten
                    im Auftrag des Unternehmens verarbeitet. Es bezieht sich auf Drittunternehmen oder Personen, die vom
                    Unternehmen beschäftigt werden, um den Service zu erleichtern, den Service im Namen des Unternehmens
                    bereitzustellen, Dienstleistungen im Zusammenhang mit dem Service zu erbringen oder das Unternehmen
                    bei der Analyse der Nutzung des Service zu unterstützen.</p>

                </li>
                <li><p><strong>Nutzungsdaten</strong> beziehen sich auf automatisch erhobene Daten, die entweder durch
                    die Nutzung des Dienstes oder von der Dienstinfrastruktur selbst generiert werden (z. B. die Dauer
                    eines Seitenbesuchs).</p>

                </li>
                <li><p><strong>Die Website</strong> verweist auf {site_name} , zugänglich über {site_host}
                </p>

                </li>
                <li><p><strong>Sie</strong> bezeichnen die Person, die auf den Dienst zugreift oder ihn nutzt, oder das
                    Unternehmen oder eine andere juristische Person, in deren Namen diese Person gegebenenfalls auf den
                    Dienst zugreift oder ihn nutzt.</p>

                </li>
            </ul>
            <h1>Erhebung und Verwendung Ihrer personenbezogenen Daten</h1>

            <h2>Arten der erfassten Daten</h2>
            <h3>Persönliche Daten</h3><p>Während der Nutzung unseres Dienstes können wir Sie bitten, uns bestimmte
            personenbezogene Daten zur Verfügung zu stellen, die verwendet werden können, um Sie zu kontaktieren
            oder zu identifizieren. Persönlich identifizierbare Informationen können beinhalten, sind aber nicht
            beschränkt auf:</p>


            <ul>
                <li>Nutzungsdaten</li>
            </ul>
            <h3>Nutzungsdaten</h3><p>Nutzungsdaten werden bei der Nutzung des Dienstes automatisch erhoben.</p>


            <p>Nutzungsdaten können Informationen wie die Internetprotokolladresse Ihres Geräts (z. B. IP-Adresse),
                Browsertyp, Browserversion, die Seiten unseres Dienstes, die Sie besuchen, die Uhrzeit und das Datum
                Ihres Besuchs, die auf diesen Seiten verbrachte Zeit, das einzelne Gerät enthalten Kennungen und andere
                diagnostische Daten.</p>

            <p>Wenn Sie über ein mobiles Gerät auf den Dienst zugreifen, können wir
                bestimmte Informationen automatisch erfassen, einschließlich, aber nicht beschränkt auf den Typ des von
                Ihnen verwendeten mobilen Geräts, die eindeutige ID Ihres mobilen Geräts, die IP-Adresse Ihres mobilen
                Geräts, Ihr Mobiltelefon Betriebssystem, die Art des von Ihnen verwendeten mobilen Internetbrowsers,
                eindeutige Gerätekennungen und andere Diagnosedaten.</p>

            <p>Wir können auch Informationen sammeln, die
                Ihr Browser sendet, wenn Sie unseren Service besuchen oder wenn Sie mit oder über ein mobiles Gerät auf
                den Service zugreifen.</p>

            <h3>Tracking-Technologien und Cookies</h3><p>Wir verwenden Cookies und
            ähnliche Tracking-Technologien, um die Aktivitäten auf unserem Service zu verfolgen und bestimmte
            Informationen zu speichern. Verwendete Tracking-Technologien sind Beacons, Tags und Skripte, um
            Informationen zu sammeln und zu verfolgen und unseren Service zu verbessern und zu analysieren. Zu den
            von uns verwendeten Technologien können gehören:</p>


            <ul>
                <li><strong>Cookies oder Browser-Cookies. </strong>Ein Cookie ist eine kleine Datei, die auf Ihrem Gerät
                    abgelegt wird. Sie können Ihren Browser anweisen, alle Cookies abzulehnen oder anzuzeigen, wenn ein
                    Cookie gesendet wird. Wenn Sie jedoch keine Cookies akzeptieren, können Sie möglicherweise einige
                    Teile unseres Dienstes nicht nutzen. Sofern Sie Ihren Browser nicht so eingestellt haben, dass er
                    Cookies ablehnt, kann unser Service Cookies verwenden.
                </li>
                <li><strong>Flash-Cookies. </strong>Bestimmte Funktionen unseres Dienstes können lokal gespeicherte
                    Objekte (oder Flash-Cookies) verwenden, um Informationen über Ihre Präferenzen oder Ihre Aktivitäten
                    in unserem Dienst zu sammeln und zu speichern. Flash-Cookies werden nicht von denselben
                    Browsereinstellungen verwaltet wie die für Browser-Cookies verwendeten. Weitere Informationen
                    darüber, wie Sie Flash-Cookies löschen können, finden Sie unter „Wo kann ich die Einstellungen zum
                    Deaktivieren oder Löschen von lokal freigegebenen Objekten ändern?“ verfügbar unter
                    https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_
                </li>
                <li><strong>Web-Beacons. </strong>Bestimmte Bereiche unseres Dienstes und unserer E-Mails können kleine
                    elektronische Dateien enthalten, die als Web Beacons bekannt sind (auch als Clear Gifs, Pixel Tags
                    und Single-Pixel-Gifs bezeichnet), die es dem Unternehmen beispielsweise ermöglichen, Benutzer zu
                    zählen, die diese Seiten besucht haben oder eine E-Mail geöffnet haben und für andere verwandte
                    Website-Statistiken (z. B. Aufzeichnung der Popularität eines bestimmten Abschnitts und Überprüfung
                    der System- und Serverintegrität).
                </li>
            </ul>
            <p>Cookies können „Persistent“- oder „Session“-Cookies sein. Permanente Cookies verbleiben auf Ihrem PC oder
                Mobilgerät, wenn Sie offline gehen, während Sitzungscookies gelöscht werden, sobald Sie Ihren Webbrowser
                schließen.</p>

            <p>Wir verwenden sowohl Sitzungs- als auch dauerhafte Cookies für die unten aufgeführten
                Zwecke:</p>


            <ul>
                <li><p><strong>Notwendige / Wesentliche Cookies</strong></p>

                    <p>Typ: Sitzungscookies</p>

                    <p>Verwaltet von:
                        Uns</p>

                    <p>Zweck: Diese Cookies sind unerlässlich, um Ihnen die über die Website verfügbaren Dienste
                        bereitzustellen und Ihnen die Nutzung einiger ihrer Funktionen zu ermöglichen. Sie helfen dabei,
                        Benutzer zu authentifizieren und eine betrügerische Verwendung von Benutzerkonten zu verhindern.
                        Ohne diese Cookies können die von Ihnen angeforderten Dienste nicht bereitgestellt werden, und
                        wir
                        verwenden diese Cookies nur, um Ihnen diese Dienste bereitzustellen.</p>

                </li>
                <li><p><strong>Cookie-Richtlinie / Hinweis zur Annahme von Cookies</strong></p>

                    <p>Typ: Persistente
                        Cookies</p>

                    <p>Verwaltet von: Uns</p>

                    <p>Zweck: Diese Cookies identifizieren, ob Benutzer der
                        Verwendung von Cookies auf der Website zugestimmt haben.</p>

                </li>
                <li><p><strong>Funktionalitäts-Cookies</strong></p>

                    <p>Typ: Persistente Cookies</p>

                    <p>Verwaltet von:
                        Uns</p>

                    <p>Zweck: Diese Cookies ermöglichen es uns, Entscheidungen zu speichern, die Sie treffen,
                        wenn Sie die Website nutzen, wie z. B. das Speichern Ihrer Anmeldedaten oder
                        Spracheinstellungen.
                        Der Zweck dieser Cookies besteht darin, Ihnen ein persönlicheres Erlebnis zu bieten und zu
                        vermeiden, dass Sie Ihre Einstellungen jedes Mal erneut eingeben müssen, wenn Sie die Website
                        nutzen.</p>

                </li>
            </ul>
            <p>Weitere Informationen zu den von uns verwendeten Cookies und Ihren Auswahlmöglichkeiten in Bezug auf
                Cookies finden Sie in unserer Cookie-Richtlinie oder im Abschnitt Cookies unserer
                Datenschutzrichtlinie.</p>

            <h2>Verwendung Ihrer personenbezogenen Daten</h2><p>Das Unternehmen kann
            personenbezogene Daten für die folgenden Zwecke verwenden:</p>


            <ul>
                <li><p><strong>Zur Bereitstellung und Wartung unseres Dienstes</strong> , einschließlich der Überwachung
                    der Nutzung unseres Dienstes.</p>

                </li>
                <li><p><strong>Um Ihr Konto</strong> zu verwalten: um Ihre Registrierung als Benutzer des Dienstes zu
                    verwalten. Die von Ihnen bereitgestellten personenbezogenen Daten können Ihnen Zugriff auf
                    verschiedene Funktionen des Dienstes geben, die Ihnen als registrierter Benutzer zur Verfügung
                    stehen.</p>

                </li>
                <li><p><strong>Für die Erfüllung eines Vertrages:</strong> die Entwicklung, Einhaltung und Durchführung
                    des Kaufvertrags für die Produkte, Artikel oder Dienstleistungen, die Sie gekauft haben, oder eines
                    anderen Vertrages mit uns über den Dienst.</p>

                </li>
                <li><p><strong>Um Sie zu kontaktieren:</strong> Um Sie per E-Mail, Telefonanrufen, SMS oder anderen
                    gleichwertigen Formen der elektronischen Kommunikation zu kontaktieren, wie z. wenn dies für ihre
                    Umsetzung erforderlich oder angemessen ist.</p>

                </li>
                <li><p><strong>Um Ihnen</strong> Neuigkeiten, Sonderangebote und allgemeine Informationen über andere
                    von uns angebotene Waren, Dienstleistungen und Veranstaltungen zukommen zu lassen, die denen ähneln,
                    die Sie bereits gekauft oder nach denen Sie sich erkundigt haben, es sei denn, Sie haben sich
                    entschieden, solche Informationen nicht zu erhalten.</p>

                </li>
                <li><p><strong>Um Ihre Anfragen zu verwalten:</strong> Um Ihre Anfragen an uns zu bearbeiten und zu
                    verwalten.</p>

                </li>
                <li><p><strong>Für Geschäftsübertragungen:</strong> Wir können Ihre Daten verwenden, um eine Fusion,
                    Veräußerung, Umstrukturierung, Reorganisation, Auflösung oder einen anderen Verkauf oder eine
                    Übertragung einiger oder aller unserer Vermögenswerte zu bewerten oder durchzuführen, sei es als
                    fortgeführtes Unternehmen oder als Teil einer Insolvenz, Liquidation, oder ein ähnliches Verfahren,
                    bei dem personenbezogene Daten, die wir über unsere Servicebenutzer gespeichert haben, zu den
                    übertragenen Vermögenswerten gehören.</p>

                </li>
                <li><p><strong>Für andere Zwecke</strong> : Wir können Ihre Informationen für andere Zwecke verwenden,
                    wie z. B. Datenanalyse, Erkennung von Nutzungstrends, Bestimmung der Wirksamkeit unserer
                    Werbekampagnen und zur Bewertung und Verbesserung unseres Dienstes, unserer Produkte,
                    Dienstleistungen, unseres Marketings und Ihrer Erfahrung.</p>

                </li>
            </ul>
            <p>Wir können Ihre personenbezogenen Daten in den folgenden Situationen weitergeben:</p>


            <ul>
                <li><strong>An Dienstleister:</strong> Wir können Ihre personenbezogenen Daten an Dienstleister
                    weitergeben, um die Nutzung unseres Dienstes zu überwachen und zu analysieren und Sie zu
                    kontaktieren.
                </li>
                <li><strong>Für Geschäftsübertragungen:</strong> Wir können Ihre personenbezogenen Daten im Zusammenhang
                    mit oder während Verhandlungen über eine Fusion, den Verkauf von Unternehmensvermögen, die
                    Finanzierung oder den Erwerb unseres gesamten oder eines Teils unseres Geschäfts an ein anderes
                    Unternehmen weitergeben oder übertragen.
                </li>
                <li><strong>An verbundene Unternehmen :</strong> Wir können Ihre Daten an unsere verbundenen Unternehmen
                    weitergeben. In diesem Fall verlangen wir von diesen verbundenen Unternehmen, dass sie diese
                    Datenschutzrichtlinie einhalten. Verbundene Unternehmen umfassen unsere Muttergesellschaft und alle
                    anderen Tochtergesellschaften, Joint-Venture-Partner oder andere Unternehmen, die wir kontrollieren
                    oder die unter gemeinsamer Kontrolle mit uns stehen.
                </li>
                <li><strong>An Geschäftspartner:</strong> Wir können Ihre Informationen an unsere Geschäftspartner
                    weitergeben, um Ihnen bestimmte Produkte, Dienstleistungen oder Werbeaktionen anzubieten.
                </li>
                <li><strong>Mit anderen Benutzern:</strong> Wenn Sie persönliche Informationen teilen oder anderweitig
                    in den öffentlichen Bereichen mit anderen Benutzern interagieren, können diese Informationen von
                    allen Benutzern eingesehen und öffentlich nach außen verbreitet werden.
                </li>
                <li><strong>Mit Ihrer Zustimmung</strong> : Wir können Ihre personenbezogenen Daten mit Ihrer Zustimmung
                    für andere Zwecke offenlegen.
                </li>
            </ul>
            <h2>Aufbewahrung Ihrer personenbezogenen Daten</h2><p>Das Unternehmen speichert Ihre personenbezogenen Daten
            nur so lange, wie dies für die in dieser Datenschutzrichtlinie dargelegten Zwecke erforderlich ist. Wir
            speichern und verwenden Ihre personenbezogenen Daten in dem Umfang, der erforderlich ist, um unseren
            gesetzlichen Verpflichtungen nachzukommen (z. B. wenn wir verpflichtet sind, Ihre Daten zur Einhaltung
            geltender Gesetze aufzubewahren), Streitigkeiten beizulegen und unsere gesetzlichen Vereinbarungen und
            Richtlinien durchzusetzen.</p>

            <p>Das Unternehmen speichert Nutzungsdaten auch für interne Analysezwecke.
                Nutzungsdaten werden im Allgemeinen für einen kürzeren Zeitraum aufbewahrt, es sei denn, diese Daten
                werden zur Erhöhung der Sicherheit oder zur Verbesserung der Funktionalität unseres Dienstes verwendet,
                oder wir sind gesetzlich verpflichtet, diese Daten für längere Zeiträume aufzubewahren.</p>


            <h2>Übermittlung Ihrer personenbezogenen Daten</h2><p>Ihre Informationen, einschließlich personenbezogener
            Daten, werden in den Betriebsbüros des Unternehmens und an allen anderen Orten verarbeitet, an denen
            sich die an der Verarbeitung beteiligten Parteien befinden. Dies bedeutet, dass diese Informationen an
            Computer außerhalb Ihres Staates, Ihrer Provinz, Ihres Landes oder einer anderen staatlichen
            Gerichtsbarkeit übertragen und dort gespeichert werden können, wo die Datenschutzgesetze von denen Ihrer
            Gerichtsbarkeit abweichen können.</p>

            <p>Ihre Zustimmung zu dieser Datenschutzrichtlinie, gefolgt von
                Ihrer Übermittlung dieser Informationen, stellt Ihre Zustimmung zu dieser Übertragung dar.</p>

            <p>Das
                Unternehmen wird alle vernünftigerweise notwendigen Schritte unternehmen, um sicherzustellen, dass Ihre
                Daten sicher und in Übereinstimmung mit dieser Datenschutzrichtlinie behandelt werden, und es findet
                keine Übertragung Ihrer personenbezogenen Daten an eine Organisation oder ein Land statt, es sei denn,
                es sind angemessene Kontrollen vorhanden, einschließlich der Sicherheit von Ihre Daten und andere
                persönliche Informationen.</p>

            <h2>Offenlegung Ihrer personenbezogenen Daten</h2><h3>Geschäftliche
            Transaktionen</h3><p>Wenn das Unternehmen an einer Fusion, Übernahme oder einem Verkauf von
            Vermögenswerten beteiligt ist, können Ihre personenbezogenen Daten übertragen werden. Wir werden Sie
            benachrichtigen, bevor Ihre personenbezogenen Daten übertragen werden und einer anderen
            Datenschutzrichtlinie unterliegen.</p>

            <h3>Strafverfolgung</h3><p>Unter bestimmten Umständen kann das
            Unternehmen verpflichtet sein, Ihre personenbezogenen Daten offenzulegen, wenn dies gesetzlich
            vorgeschrieben ist oder als Reaktion auf gültige Anfragen von Behörden (z. B. einem Gericht oder einer
            Regierungsbehörde).</p>

            <h3>Andere gesetzliche Anforderungen</h3><p>Das Unternehmen kann Ihre
            personenbezogenen Daten in gutem Glauben offenlegen, dass eine solche Maßnahme erforderlich ist, um:</p>


            <ul>
                <li>Einer gesetzlichen Verpflichtung nachkommen</li>
                <li>Schutz und Verteidigung der Rechte oder des Eigentums des Unternehmens</li>
                <li>Verhindern oder untersuchen Sie mögliches Fehlverhalten im Zusammenhang mit dem Dienst</li>
                <li>Schützen Sie die persönliche Sicherheit der Benutzer des Dienstes oder der Öffentlichkeit</li>
                <li>Schützen Sie sich vor gesetzlicher Haftung</li>
            </ul>
            <h2>Sicherheit Ihrer persönlichen Daten</h2><p>Die Sicherheit Ihrer personenbezogenen Daten ist uns wichtig,
            aber denken Sie daran, dass keine Übertragungsmethode über das Internet oder elektronische
            Speichermethode 100 % sicher ist. Obwohl wir uns bemühen, kommerziell akzeptable Mittel zum Schutz Ihrer
            personenbezogenen Daten zu verwenden, können wir deren absolute Sicherheit nicht garantieren.</p>


            <h1>Datenschutz von Kindern</h1>

            <p>Unser Service richtet sich nicht an Personen unter 13 Jahren. Wir
                erfassen wissentlich keine personenbezogenen Daten von Personen unter 13 Jahren. Wenn Sie ein Elternteil
                oder Erziehungsberechtigter sind und wissen, dass Ihr Kind uns personenbezogene Daten zur Verfügung
                gestellt hat, bitte kontaktiere uns. Wenn uns bekannt wird, dass wir personenbezogene Daten von Personen
                unter 13 Jahren ohne Überprüfung der elterlichen Zustimmung gesammelt haben, ergreifen wir Maßnahmen, um
                diese Informationen von unseren Servern zu entfernen.</p>

            <p>Wenn wir uns auf die Zustimmung als
                Rechtsgrundlage für die Verarbeitung Ihrer Daten verlassen müssen und Ihr Land die Zustimmung eines
                Elternteils erfordert, können wir die Zustimmung Ihrer Eltern verlangen, bevor wir diese Daten erfassen
                und verwenden.</p>

            <h1>Links zu anderen Websites</h1>

            <p>Unser Service kann Links zu anderen Websites
                enthalten, die nicht von uns betrieben werden. Wenn Sie auf einen Link eines Drittanbieters klicken,
                werden Sie auf die Website dieses Drittanbieters weitergeleitet. Wir empfehlen Ihnen dringend, die
                Datenschutzrichtlinie jeder von Ihnen besuchten Website zu lesen.</p>

            <p>Wir haben keine Kontrolle über
                und übernehmen keine Verantwortung für die Inhalte, Datenschutzrichtlinien oder Praktiken von Websites
                oder Diensten Dritter.</p>

            <h1>Änderungen an dieser Datenschutzrichtlinie</h1>

            <p>Wir können unsere
                Datenschutzrichtlinie von Zeit zu Zeit aktualisieren. Wir werden Sie über alle Änderungen informieren,
                indem wir die neue Datenschutzrichtlinie auf dieser Seite veröffentlichen.</p>

            <p>Bevor die Änderung in
                Kraft tritt, werden wir Sie per E-Mail und/oder durch einen deutlichen Hinweis auf unserem Dienst
                informieren und das Datum der „letzten Aktualisierung“ oben in dieser Datenschutzrichtlinie
                aktualisieren.</p>

            <p>Es wird empfohlen, diese Datenschutzrichtlinie regelmäßig auf Änderungen zu
                überprüfen. Änderungen an dieser Datenschutzrichtlinie treten in Kraft, wenn sie auf dieser Seite
                veröffentlicht werden.</p>

            <h1>Kontaktiere uns</h1>

            <p>Wenn Sie Fragen zu dieser Datenschutzrichtlinie
                haben, können Sie uns kontaktieren:</p>


            <ul>
                <li>Per E-Mail: {admin_email}</li>
            </ul>
        </div>
    );
};

export default PrivacyPolicyDe;
