import React from "react";

function CookiePolicyDe({site_name, site_host, admin_email}) {
    return (
        <div>
            <h1>Cookie-Richtlinie für {site_name}</h1>

            <p>Dies ist die Cookie-Richtlinie für {site_name}, zugänglich
            über {site_host}</p>

            <p><strong>Was sind Cookies</strong></p>

            <p>Wie bei fast allen
            professionellen Websites üblich, verwendet diese Website Cookies, kleine Dateien, die auf Ihren Computer
            heruntergeladen werden, um Ihr Erlebnis zu verbessern. Diese Seite beschreibt, welche Informationen sie
            sammeln, wie wir sie verwenden und warum wir diese Cookies manchmal speichern müssen. Wir werden Ihnen auch
            mitteilen, wie Sie verhindern können, dass diese Cookies gespeichert werden, dies kann jedoch bestimmte
            Elemente der Website-Funktionalität herabsetzen oder „brechen“.</p>

            <p><strong>Wie wir Cookies
            verwenden</strong></p>

            <p>Wir verwenden Cookies aus einer Vielzahl von Gründen, die unten aufgeführt sind.
            Leider gibt es in den meisten Fällen keine branchenüblichen Optionen zum Deaktivieren von Cookies, ohne die
            Funktionalität und Features, die sie dieser Website hinzufügen, vollständig zu deaktivieren. Es wird
            empfohlen, alle Cookies aktiviert zu lassen, wenn Sie sich nicht sicher sind, ob Sie sie benötigen oder
            nicht, falls sie verwendet werden, um einen von Ihnen genutzten Dienst bereitzustellen.</p>

            <p><strong>Cookies
            deaktivieren</strong></p>

            <p>Sie können das Setzen von Cookies verhindern, indem Sie die Einstellungen Ihres
            Browsers anpassen (Informationen dazu finden Sie in der Hilfe Ihres Browsers). Beachten Sie, dass das
            Deaktivieren von Cookies die Funktionalität dieser und vieler anderer Websites, die Sie besuchen,
            beeinträchtigt. Das Deaktivieren von Cookies führt normalerweise auch dazu, dass bestimmte Funktionen und
            Merkmale dieser Website deaktiviert werden. Daher wird empfohlen, Cookies nicht zu deaktivieren.</p>

            <p>
            <strong>Die Cookies, die wir setzen</strong></p>
            <ul>
                <li><p>Cookies für Website-Einstellungen</p><p>Um Ihnen ein großartiges Erlebnis auf dieser Website zu
                    bieten, stellen wir Ihnen die Funktionalität zur Verfügung, mit der Sie Ihre Präferenzen für die
                    Ausführung dieser Website festlegen können, wenn Sie sie verwenden. Um Ihre Präferenzen zu
                    speichern, müssen wir Cookies setzen, damit diese Informationen abgerufen werden können, wenn Sie
                    mit einer Seite interagieren, die von Ihren Präferenzen beeinflusst wird.</p></li>
            </ul>
            <p><strong>Cookies von Drittanbietern</strong></p><p>In einigen Sonderfällen verwenden wir auch Cookies, die
                von vertrauenswürdigen Drittanbietern bereitgestellt werden. Der folgende Abschnitt beschreibt, auf
                welche Cookies von Drittanbietern Sie möglicherweise über diese Website stoßen.</p>
            <ul>
                <li><p>Von Zeit zu Zeit testen wir neue Funktionen und nehmen subtile Änderungen an der Art und Weise
                    vor, wie die Website bereitgestellt wird. Wenn wir noch neue Funktionen testen, können diese Cookies
                    verwendet werden, um sicherzustellen, dass Sie auf der Website ein konsistentes Erlebnis erhalten,
                    während wir sicherstellen, dass wir verstehen, welche Optimierungen unsere Benutzer am meisten
                    schätzen.</p></li>
                <li><p>Der Google AdSense-Dienst, den wir verwenden, um Werbung zu schalten, verwendet ein
                    DoubleClick-Cookie, um relevantere Anzeigen im Internet zu schalten und die Häufigkeit zu begrenzen,
                    mit der Ihnen eine bestimmte Anzeige gezeigt wird.</p><p>Weitere Informationen zu Google AdSense
                    finden Sie in den offiziellen häufig gestellten Fragen zum Datenschutz von Google AdSense.</p></li>
            </ul>

            <p><strong>Mehr Informationen</strong></p><p>Hoffentlich hat das die Dinge für Sie geklärt, und wie bereits
                erwähnt, wenn Sie sich nicht sicher sind, ob Sie Cookies benötigen oder nicht, ist es normalerweise
                sicherer, Cookies aktiviert zu lassen, falls sie mit einer der Funktionen interagieren, die Sie auf
                unserer Website verwenden.</p><p>Wenn Sie jedoch immer noch nach weiteren Informationen suchen, können
                Sie uns über eine unserer bevorzugten Kontaktmethoden kontaktieren:</p>
            <ul>
                <li>E- Mail: {admin_email}</li>
            </ul>
        </div>
    );
};

export default CookiePolicyDe;
